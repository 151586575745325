<script>
import sessionsApi from "../../common/api/sessionsAxios";

export default {
  components: {
    Modal: () => import("@/components/modals/Modal.vue"),
  },
  data() {
    return {
      appsToClose: [
        {
          name: 'MySpace1A',
          selected: false,
          keyUser: 'isOnline'
        },
        {
          name: 'Jasmin',
          selected: false,
          keyUser: 'isBroadcastingOnJasmin'
        },
        {
          name: 'Streamate',
          selected: false,
          keyUser: 'isBroadcastingOnStreamate'
        },
      ]
    };
  },
  computed: {
    user() {
      return this.$store.state.user.ModelFinish;
    },
    appsSendToClose(){
      return this.appsActive.filter(({ selected }) => selected).map(({name}) => name)
    },
    appsActive(){
      return this.appsToClose.filter(({ keyUser }) => this.user[keyUser])
    }
  },
  methods: {
    setAppClose(app){      
      const newVal = !app.selected

      if (app.name === "MySpace1A" && newVal){
        this.appsToClose.forEach(appKey => appKey.selected = newVal)
        return
      }

      app.selected = newVal
    },
    close() {
      this.$store.state.user.ModelFinish = {};
    },
    async sendApps() {
      if (!this.appsSendToClose.length){
        return;
      } 

      await sessionsApi.post(`/session/closeApp`, { user: this.user,  appsToClose: this.appsSendToClose });      
  
      this.close();
    },
  },
};
</script>

<template>
  <Modal class="modalFinish" :isDisableSubmit="!appsSendToClose.length" @onCancel="close" @onSubmit="sendApps">
    <template v-slot:header>
      <div class="modalFinish__header">
        <h2 class="modalFinish__title">Desconexión</h2>
      </div>
    </template>
    <template v-slot:body>
      <div class="modalFinish__body">
        <button :class="['modalFinish__reason', app.selected && 'modalFinish__reason--active']" v-for="(app, key) in appsActive" @click="() => setAppClose(app)" :key="key">
          {{app.name}}
        </button>
      </div>
    </template>
    <template v-slot:footer></template>
  </Modal>
</template>

<style lang="scss">
.modalFinish {
  .modal-g__container {
    padding: $mpadding * 1.5 $mpadding;
    max-width: 300px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  .modal-g__buttons {
    @include Flex(row);
  }
  .modal-g__button {
    @include Flex(row);
    width: 110px;
    height: 36px;
    padding: 8px;
    font-weight: bold;
    color: $primary-color;
    background: white;
    border: 1px solid $primary-color;
    border-radius: 6px;
  }
  .modal-g__button.submit {
    color: white;
    background: $primary-color;
  }
  &__header {
    text-align: center;
  }
  &__header,
  &__body {
    width: 100%;
  }
  &__body {
    @include Flex(column);
    padding: $mpadding/2;
  }
  &__reason {
    @include Flex(row, space-around);
    width: 140px;
    height: 50px;
    padding: 10px 19px;
    margin: $mpadding/2 0;
    border: none;
    border-radius: 6px;
    background: #efefef;
    cursor: pointer;
    &--active {
      background: $primary-color;
      color: white;
      font-weight: bold;
      transition: 0.3s ease-in-out;
    }
  }
}
</style>
